<template lang="pug">

b-container(fluid)
  b-row(v-if='!loaded && !loadingError' class='mt-5')
    b-col(class='text-center')
      b-spinner(label='Spinning')

  b-row(v-if='loadingError' class='mt-5')
    b-col(class='text-center') {{ loadingError }}

  b-row(v-if='taskAttribute == false' class='mt-5')
    b-col(class='text-center') Not found

  b-row(v-if="loaded", class='mb-4')
    b-col(cols=12)
      h1 
        template(v-if='taskAttribute.ParentTaskAttribute') 
          | Parent: {{ taskAttribute.ParentTaskAttribute.Description }}
          small.text-muted &nbsp; {{ taskAttribute.ParentTaskAttribute.TaskReference }} 
        template(v-else) 
          | {{ taskAttribute.Description }}
          small.text-muted &nbsp; {{ taskAttribute.TaskReference }}
      h2(v-if='taskAttribute.ParentTaskAttribute')
        | {{ taskAttribute.Description }}
        small.text-muted &nbsp; {{ taskAttribute.TaskReference }}
      // {{ taskAttribute.AttributeType.Description }} 

    b-col.mb-3(
      cols=12, 
      v-if=`
        taskAttribute.ParentTaskAttributeID && (
          $root.$data.me.IsSuperUser || 
          ['CP1', 'CP2'].includes(taskAttribute.ParentTaskAttribute.BestServiceTag)
        )
      `
      )
      router-link(
        :to=`{ 
          name: 'task-detail-overview', 
          params: { id: taskAttribute.ParentTaskAttributeID } 
        }`
      ) Go to parent - {{ taskAttribute.ParentTaskAttribute.Description }}...

    b-col(cols=12)
      b-card
        ul.nav.nav-pills
          li.nav-item(v-for='tab in visibleTabs')
            router-link.nav-link(:class=`{ 
                active: $route.fullPath == tab.to,
              }` 
              :to="tab.to"
            ) {{ tab.title }}

      router-view.px-0(
        :task-attribute='combinedTaskAttribute'
        :holder='combinedTaskAttribute'
        holder-type='TaskAttribute'
        :holder-id='taskAttribute.TaskAttributeID'
      )

</template>

<script>
  export default {
    data () {
      return {
        attributesLoaded: false,
        taskLoaded: false,
        loadingError: false,
        taskAttribute: {},
        attributes: {},
        subTasks: [],
      }
    },
    props: {
      id: String
    },
    provide () {
      return {
        setTaskInstance: this.setTaskInstance,
        reloadTask: this.loadTask,
        createDefaultFileStructure: this.createDefaultFileStructure
      }
    },
    watch: {
      $route(to, from) {
        if (to.params.id != from.params.id) {
          this.loadTask()
        } else if (['task-detail-attributes', 'task-detail-basket-track-selection'].includes(from.name)) {
          this.loadTask()
          //this.loadAttributes()
        }
      }
    },
    computed: {
      loaded () { return this.attributesLoaded && this.taskLoaded },
      visibleTabs () {
        const tabs = [
          { title: 'Overview', to: { name: 'task-detail-overview' } },
          { title: 'Edit', to: { name: 'task-detail-edit' } },
          { 
            title: 'Data entry', 
            to: { name: 'task-detail-dataentry' },
            visible: () => { return this.combinedTaskAttribute.attributeTypes.DataEntry != undefined },
          },
          { 
            title: 'Attributes', 
            to: { name: 'task-detail-attributes' },
            visible: () => { return this.$root.$data.me.access.CP1 || this.$root.$data.me.access.CP2 },
          },
          { 
            title: 'Basket', 
            to: { name: 'task-detail-basket' },
            visible: () => { return this.taskAttribute.AttributeTypeID == '4B3569DB-A7E8-46A6-A88F-DB6CEB00FA5E' },
          },
          { 
            title: 'Track selection', 
            to: { name: 'task-detail-basket-track-selection' },
            visible: () => { 
              return (
                this.taskAttribute.AttributeTypeID == '4B3569DB-A7E8-46A6-A88F-DB6CEB00FA5E' &&
                this.taskAttribute.BasketID
              )
            },
          },
          { 
            title: 'Tasks', 
            to: { name: 'task-detail-tasks' },
            visible: () => { return this.taskAttribute.AttributeTypeID == '4B3569DB-A7E8-46A6-A88F-DB6CEB00FA5E' },
          },
          { 
            title: 'Files', 
            to: { name: 'task-detail-files', params: ['/'] }, 
            visible: () => { 
              return (
                  this.taskAttribute.AttributeTypeID == '4B3569DB-A7E8-46A6-A88F-DB6CEB00FA5E' ||
                  this.combinedTaskAttribute.attributeTypes.FileAccess != undefined
                )
              },
          },
        ]
        
        if (this.taskAttribute.Albums) {
         for (const [albumId, album] of Object.entries(this.taskAttribute.Albums))
          {
            tabs.push({
              title: `Album: ${album.OriginalReleaseFormat}`,
              to: { name: 'task-detail-album', params: { albumId: albumId } },
            })
          }
        }

        return tabs.filter(tab => { 
          return tab.visible ? tab.visible() : true 
        }).map(tab => {
          tab.to = this.$router.resolve(tab.to).href
          return tab
        })
      },
      combinedTaskAttribute () {
        const data = JSON.parse(JSON.stringify(this.taskAttribute))
        data.attributes = JSON.parse(JSON.stringify(this.attributes))

        data.attributeTypes = {}
        for (const [categoryId, category] of Object.entries(data.attributes)) {
          for (const [typeId, attributeType] of Object.entries(category.Types)) {
            attributeType.AttributeCategoryID = categoryId
            attributeType.AttributeTypeID = typeId
            data.attributeTypes[attributeType.Description.replaceAll(' ', '')] = attributeType
          }
        }

        //data.subTasks = JSON.parse(JSON.stringify(this.subTasks))

        /*
        data.albums = data.attributeTypes.ProjectAlbumID?.Values.map(album => {
          return album.Value
        }) */

        return data
      },
    },
    methods: {
      loadTask () {
        this.taskLoaded = false
        this.$http.get('task-attributes/'+this.id, {
        })
        .then(response => {
          this.setTaskInstance(response.data)
        })
        .catch(() => {
          this.loadingError = 'An error occurred while loading, please reload the page to try again'
        })

        this.loadAttributes()
        //this.loadSubTasks()
      },
      async createDefaultFileStructure () {
        this.taskLoaded = false
        await this.$http.put(`task-attributes/${this.id}/create-default-file-structure`)
        this.loadTask()
      },
      async loadAttributes () {
        this.attributesLoaded = false
        this.attributes = {}
        try {
          const response = await this.$http.get(`task-attributes/${this.id}/attributes`)
          this.attributes = response.data
          this.attributesLoaded = true
        } catch {
          this.loadingError = 'An error occurred while loading, please reload the page to try again'
        }
      },
      async loadSubTasks () {
        this.subTasksLoaded = false
        this.subTasks = []
        try {
          console.log('loading attributes')
          const response = await this.$http.get(`task-attributes/${this.id}/attributes`)
          this.subTasks = response.data
          this.subTasksLoaded = true
        } catch {
          this.loadingError = 'An error occurred while loading, please reload the page to try again'
        }
      },
      setTaskInstance (task) {
        this.taskLoaded = false
        this.$set(this, 'taskAttribute', task)
        this.$nextTick(() => {
          this.taskLoaded = true
        })
      },
    },
    mounted () {
      this.loadTask()
    }
  }
</script>

<style>

.card-header-tabs-with-nav {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

</style>